import styles from './InfoBox.module.css';

function InfoBox({title, content}) {
    return(
        <>
            <div className={styles.infoBoxContainer}>
                <h3 className={styles.infoBoxTitle}>{title}</h3>
                <div className={styles.infoBoxSeparator}></div>
                <p 
                className='regular-p'
                dangerouslySetInnerHTML={{__html: content}}
                ></p>
            </div>
        </>
    )
}

export default InfoBox;