import { Container, Row, Col, Button } from "react-bootstrap";
import usePageSEO from "../Utils/usePageSEO";
import ContactForm from "../components/ContactForm";
import { useOutletContext } from 'react-router-dom';

import styles from './Nosotros.module.css';
import { nosotrosContentES, nosotrosContentEN } from "../Assets/nosotros-content";

import picEcoFriendly from '../Assets/hand-holding-eco-friendly-green-living-symbol 2.jpg';
import bgServicios from '../Assets/bg-servicios.svg';
import sustenlogicIso from '../Assets/sustenlogic-iso.svg';

import InfoBox from "../components/InfoBox";
import IconText from "../components/IconText";
import Footer from "../components/Footer";

function Nosotros(){

    const { lang } = useOutletContext();
    let nosotrosContent = lang === 'es' ? nosotrosContentES : nosotrosContentEN

    usePageSEO(nosotrosContent.nosotrosSEO);

    return(
        <>
            <Container fluid className={styles.nosotrosHero}>
                <Row>
                    <Col className='d-flex flex-column align-items-start align-items-lg-center'>
                        <h1 className={`${styles.nosotrosTitle} text-white text-start text-lg-center`}>{nosotrosContent.heroTitle}</h1>
                        <p className={`regular-p text-white text-start text-lg-center ${styles.nosotrosSubtitle}`}>{nosotrosContent.heroSubTitle}</p>
                        <Button className={`btn-cta ${styles.btnCta}`} href="#contacto">
                            {nosotrosContent.heroCta}
                        </Button>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="py-5 align-items-center justify-content-center">
                    <Col xs={12} md={7} lg={5}>
                        <p 
                        className="regular-p"
                        dangerouslySetInnerHTML={{__html: nosotrosContent.p1}}
                        ></p>
                        <p className="regular-p">
                            {nosotrosContent.p2}
                        </p>
                    </Col>
                    <Col xs={12} md={5} className="d-flex justify-content-center pt-4 pt-md-0">
                    <div className={styles.nosotrosPic} style={{ backgroundImage: `url('${picEcoFriendly}')` }}>
                    </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="py-5">
                    <Col>
                        <h3 className="text-center">{nosotrosContent.visionTitle}</h3>
                    </Col>
                </Row>
                <Row className="justify-content-center gx-5 gy-3">
                    <Col xs={12} md={6} lg={5} xl={4}>
                        <InfoBox title={nosotrosContent.misionBox.title} content={nosotrosContent.misionBox.content} />
                    </Col>
                    <Col xs={12} md={6} lg={5} xl={4}>
                        <InfoBox title={nosotrosContent.visionBox.title} content={nosotrosContent.visionBox.content} />
                    </Col>
                </Row>
            </Container>
            <Container fluid className={styles.nuestrosValoresContainer}>
            <Row>
                <Col className="px-0">
                    <img src={bgServicios} alt='' className='img-fluid w-100' />
                </Col>
            </Row>
            <Row className={`${styles.nuestrosValoresTitle} justify-content-center`}>
                <Col className="px-0">
                    <h3 className="text-center mb-5">{nosotrosContent.valoresTitle}</h3>
                </Col>
            </Row>
            <Row className={`${styles.nuestrosValores} justify-content-center py-4`}>
                <Col md={3}>
                    <IconText
                        Icon={nosotrosContent.valores1.Icon}
                        Title={nosotrosContent.valores1.Title}
                        Content={nosotrosContent.valores1.Content}
                        ContentCentered={true}
                    />
                </Col>
                <Col md={3}>
                    <IconText
                        Icon={nosotrosContent.valores2.Icon}
                        Title={nosotrosContent.valores2.Title}
                        Content={nosotrosContent.valores2.Content}
                        ContentCentered={true}
                    />
                </Col>
                <Col md={3}>
                    <IconText
                        Icon={nosotrosContent.valores3.Icon}
                        Title={nosotrosContent.valores3.Title}
                        Content={nosotrosContent.valores3.Content}
                        ContentCentered={true}
                    />
                </Col>
            </Row>
            </Container>
            <Container fluid className="bg-third-green-rounded" id="contacto">
                <Row className="justify-content-center">
                    <Col className="py-5" md={8}>
                        <div className={styles.nosotrosFooterTitle}>
                            <img src={sustenlogicIso} alt=""/>
                            <h4 className="text-white text-center mt-3">
                            {nosotrosContent.footerTitle.p1}
                            </h4>
                            <h4 className="text-white text-center mt-3">
                            {nosotrosContent.footerTitle.p2}
                            </h4>
                        </div>
                    </Col>
                </Row>
                <Row className='justify-content-center mx-0 pb-5'>
                <Col md={8} lg={6} xl={5}>
                    <ContactForm lang={lang} />
                </Col>
            </Row>
            </Container>
            <Footer lang={lang} />
        </>
    )
}

export default Nosotros;