import iconTeam from '../Assets/team.png';
import iconCuore from '../Assets/cuore.png';
import iconMision from '../Assets/mision.png';
import iconStar from '../Assets/star.png';
import iconCheck from '../Assets/icon-check.png';
import ctoPic from '../Assets/cto.png';
import digitalPic from '../Assets/digital.png';
import cmoPic from '../Assets/cmo.png';

export const homeContentES = {
    heroTitulo : 'Transforma tu negocio con soluciones sostenibles',
    heroP1 : 'Impulsa tu crecimiento con tecnología avanzada que prioriza la sostenibilidad y eficiencia',
    heroCta : 'CONTÁCTANOS',
    misionTitulo : 'Nuestra misión',
    misionP1 : 'Construir soluciones accesibles para todos, utilizando la tecnología al servicio de la sociedad, sin comprometer los recursos de las generaciones futuras.',
    misionPicAlt : 'Mano sostiene icono de fábrica',
    elegirTitulo : '¿Por qué elegir Sustenlogic?',
    iconTextElegir1 : {
        Icon: iconTeam,
        Title: "Enfoque personalizado:",
        Content: "Diseñamos soluciones que se ajusten exactamente a tus necesidades y desafíos específicos."
    },
    iconTextElegir2 : {
        Icon: iconStar,
        Title: "Reducción de costos:",
        Content: "Implementamos propuestas que buscan reducir gastos operativos."
    },
    iconTextElegir3 : {
        Icon: iconMision,
        Title: "Innovación sostenible:",
        Content: "Desarrollamos tecnologías y prácticas con bajo impacto ambiental, promoviendo la sostenibilidad."
    },
    iconTextElegir4 : {
        Icon: iconCuore,
        Title: "Transparencia y confianza:",
        Content: "Mantenemos una comunicación abierta y honesta, brindándote servicios fiables respaldados por nuestra experiencia."
    },
    solucionesTitulo : 'Soluciones integrales para un futuro sostenible',
    iconTextSoluciones1 : {
        Icon: iconCheck,
        Title: "Visualizaciones detalladas",
        Content: "Comprende mejor tu impacto con gráficos y diagramas completos. Visualiza tus datos de forma intuitiva para tomar decisiones informadas y rápidas."
    },
    iconTextSoluciones2 : {
        Icon: iconCheck,
        Title: "Desarrollo meticuloso",
        Content: "Nuestras soluciones están creadas con una atención al detalle y la calidad que aseguran su funcionamiento óptimo y fiable."
    },
    iconTextSoluciones3 : {
        Icon: iconCheck,
        Title: "Innovación Adaptada",
        Content: "Creamos soluciones innovadoras a medida, diseñadas para integrarse sin problemas en tu entorno, optimizando procesos y maximizando resultados."
    },
    proyectosTitulo : 'Nuestros proyectos y servicios',
    proyectosLogoRecuperappAlt : 'Logo Recuperapp',
    proyectosRecuperappP1 : 'Con Recuperap, localiza tus objetos en todo momento. Nuestra app te permite generar alertas y estar siempre cubierto ante posibles robos. Ya no tendrás que preocuparte por perder tus pertenencias importantes.',
    proyectosCta : 'MÁS INFORMACIÓN',
    serviciosTitulo : 'Nuestros servicios',
    serviciosCta : 'CONTÁCTANOS',
    richMediaCto : {
        RMPic : ctoPic,
        RMPicPosition: "first",
        RMTitle : "Fractional CTO",
        RMContent : "Asegúrate de que tu empresa tome las mejores decisiones tecnológicas.",
        RMBullets : [
            "Asesoramiento estratégico personalizado.",
            "Planificación tecnológica a largo plazo."
        ]
    },
    richMediaDigProd : {
        RMPic : digitalPic,
        RMPicPosition: "last",
        RMTitle : "Desarrollo de productos digitales",
        RMContent : "Asegúrate de que tu empresa tome las mejores decisiones tecnológicas.",
        RMBullets : [
            "Creación de soluciones a medida con enfoque sustentable.",
            "Desarrollo de productos que agregan valor tanto a tu negocio como al mundo."
        ]
    },
    richMediaCmo : {
        RMPic : cmoPic,
        RMPicPosition: "first",
        RMTitle : "Fractional CMO",
        RMContent : "Transforma tu estrategia de marketing con un enfoque en crecimiento sostenible y responsable.",
        RMBullets : [
            "Desarrollo de estrategias específicas.",
            "Orientación y asesoramiento en marketing."
        ]
    },
    contactoTitulo : 'Nuestro compromiso',
    contactoP1 : 'En Sustenlogic, vivimos lo que decimos. Creemos en la transparencia, la coherencia y la calidad en cada acción',
    homeSEO : {
        title : 'Sustenlogic | Innovación Tecnológica para la Sostenibilidad Empresarial',
        description : 'Tecnología innovadora para empresas que buscan sostenibilidad y eficiencia. En Sustenlogic, implementamos soluciones que impulsan el crecimiento responsable y optimizan los recursos.',
        keywords : ['sostenibilidad', 'transformación', 'negocio', 'soluciones sostenibles'],
        ogTitle : 'Sustenlogic | Innovación Tecnológica para la Sostenibilidad Empresarial',
        ogDescription : 'Tecnología innovadora para empresas que buscan sostenibilidad y eficiencia. En Sustenlogic, implementamos soluciones que impulsan el crecimiento responsable y optimizan los recursos.',
        ogImage : 'https://i.imgur.com/7s8PtFA.jpeg',
        ogUrl : process.env.PUBLIC_URL,
        ogType : 'website'
    }
}

export const homeContentEN = {
    heroTitulo : 'Transform your business with sustainable solutions',
    heroP1 : 'Boost your growth with advanced technology that prioritizes sustainability and efficiency',
    heroCta : 'CONTACT US',
    misionTitulo : 'Our mission',
    misionP1 : 'Building solutions that are accessible to all, using technology to serve society, without compromising the resources of future generations.',
    misionPicAlt : 'Hand holding industry icon',
    elegirTitulo : 'Why choose Sustenlogic?',
    iconTextElegir1 : {
        Icon: iconTeam,
        Title: "Personalized approach:",
        Content: "We design solutions that fit exactly to your specific needs and challenges."
    },
    iconTextElegir2 : {
        Icon: iconStar,
        Title: "Cost reduction:",
        Content: "We implement proposals aimed at reducing operational costs."
    },
    iconTextElegir3 : {
        Icon: iconMision,
        Title: "Sustainable innovation:",
        Content: "We develop low-environmental-impact technologies and practices, promoting sustainability."
    },
    iconTextElegir4 : {
        Icon: iconCuore,
        Title: "Transparency and trust:",
        Content: "We maintain open and honest communication, providing you with reliable services backed by our expertise."
    },
    solucionesTitulo : 'Comprehensive solutions for a sustainable future',
    iconTextSoluciones1 : {
        Icon: iconCheck,
        Title: "Detailed visualizations",
        Content: "Better understand your impact with complete charts and diagrams. Visualize your data intuitively to make informed, quick decisions."
    },
    iconTextSoluciones2 : {
        Icon: iconCheck,
        Title: "Meticulous development",
        Content: "Our solutions are crafted with an attention to detail and quality that ensures optimal and reliable performance."
    },
    iconTextSoluciones3 : {
        Icon: iconCheck,
        Title: "Adapted innovation",
        Content: "We create tailor-made innovative solutions, designed to seamlessly integrate into your environment, optimizing processes and maximizing results."
    },
    proyectosTitulo : 'Our projects and services',
    proyectosLogoRecuperappAlt : 'Recuperapp Logo',
    proyectosRecuperappP1 : 'With Recuperapp, locate your items at all times. Our app allows you to generate alerts and always be covered in case of possible theft. You won’t have to worry about losing your important belongings anymore.',
    proyectosCta : 'MORE INFORMATION',
    serviciosTitulo : 'Our services',
    serviciosCta : 'CONTACT US',
    richMediaCto : {
        RMPic : ctoPic,
        RMPicPosition: "first",
        RMTitle : "Fractional CTO",
        RMContent : "Ensure your company makes the best technological decisions.",
        RMBullets : [
            "Personalized strategic advice.",
            "Long-term technology planning."
        ]
    },
    richMediaDigProd : {
        RMPic : digitalPic,
        RMPicPosition: "last",
        RMTitle : "Digital product development",
        RMContent : "Ensure your company makes the best technological decisions.",
        RMBullets : [
            "Creating customized solutions with a sustainable focus.",
            "Developing products that add value to both your business and the world."
        ]
    },
    richMediaCmo : {
        RMPic : cmoPic,
        RMPicPosition: "first",
        RMTitle : "Fractional CMO",
        RMContent : "Transform your marketing strategy with a focus on sustainable and responsible growth.",
        RMBullets : [
            "Development of specific strategies.",
            "Marketing guidance and advice."
        ]
    },
    contactoTitulo : 'Our commitment',
    contactoP1 : 'At Sustenlogic, we live what we say. We believe in transparency, consistency, and quality in every action',
    homeSEO : {
        title : 'Sustenlogic | Technological Innovation for Business Sustainability',
        description : 'Innovative technology for companies seeking sustainability and efficiency. At Sustenlogic, we implement solutions that drive responsible growth and optimize resources.',
        keywords : ['sustainability', 'transformation', 'business', 'sustainable solutions'],
        ogTitle : 'Sustenlogic | Technological Innovation for Business Sustainability',
        ogDescription : 'Innovative technology for companies seeking sustainability and efficiency. At Sustenlogic, we implement solutions that drive responsible growth and optimize resources.',
        ogImage : 'https://i.imgur.com/7s8PtFA.jpeg',
        ogUrl : process.env.PUBLIC_URL,
        ogType : 'website'
    }
}
