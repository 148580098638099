import styles from './IconText.module.css';

function IconText({Icon, Title, Content, TextColor = 'text-main-black', ContentCentered = false}){
    return (
        <>
            <div className={`d-flex flex-column align-items-center ${ContentCentered ? '' : 'align-items-lg-start'} text-center ${ContentCentered ? '' : 'text-lg-start'} mb-4`}>
                <img src={Icon} alt='Icon' className={styles.IconTextIcon} />
                <p className={`regular-p ${TextColor}`}>
                    <span className="fw-bold d-block">{Title}</span>
                    {Content}
                </p>
            </div>
        </>
    )
}

export default IconText;