import styles from './Menu.module.css';
import { Navbar, Container, Nav, Button, NavDropdown, Image } from "react-bootstrap";

import logo from '../Assets/logo-fullcolor.svg';
import langIconES from '../Assets/lang-icon-es.svg';
import langIconEN from '../Assets/lang-icon-en.svg';
import { NavLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

function Menu({lang, setLang}){
    return (
        <>
        <div className={styles.menuContainer}>
          <Navbar expand="lg" className={`${styles.navbar} ${styles.bgMenu}`}>
        <Container fluid className='g-0'>
          <Navbar.Brand href="#" className='me-0 py-0'>
              <img src={logo} alt="Logo Sustenlogic"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="ms-auto my-2 my-lg-0 align-items-center"
            >
              <Nav.Link as={NavLink} to="/" className={styles.navLink}>
                  {lang === 'es' ? 'Inicio' : 'Home'}
              </Nav.Link>
              <Nav.Link as={HashLink} to="/#recuperapp" className={styles.navLink}>
                  Recuperapp
              </Nav.Link>
              <Nav.Link as={NavLink} to="/nosotros" className={styles.navLink}>
                  {lang === 'es' ? 'Nosotros' : 'About us'}
              </Nav.Link>
              <Nav.Link as={HashLink} to="#contacto" className={styles.navLink}>
                <Button className={`${styles.btnMenuContacto} px-4`}>
                {lang === 'es' ? 'CONTÁCTANOS' : 'CONTACT US'}
                </Button>
              </Nav.Link>
              <NavDropdown title={lang === 'es' ? 'Idioma' : 'Language'} id='langSwitch' className={`${styles.navLink} ${styles.dropdownMenu}`}>
                <NavDropdown.Item onClick={()=>setLang('es')}>
                  {lang === 'es' ? 'ESP' : 'SPA'} <Image src={langIconES} />
                </NavDropdown.Item>
                <NavDropdown.Item onClick={()=>setLang('en')}>
                  {lang === 'es' ? 'ING' : 'ENG'} <Image src={langIconEN} />
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            
          </Navbar.Collapse>
        </Container>
      </Navbar>

        </div>
        </>
    )
}

export default Menu;