import { Row, Col } from "react-bootstrap";
import styles from './RichMedia.module.css';

function RichMedia({RMTitle, RMContent, RMPic, RMPicPosition, RMBullets = []}){
    return(
        <>
            <Row className={`justify-content-center ${styles.richMediaContainer} mx-0 mb-5`}>
                <Col md={{span: 4, order: RMPicPosition}}>
                    <img src={RMPic} alt='Personas trabajando en escritorio' className='img-fluid'/>
                </Col>
                <Col md={8}>
                    <div className={styles.richMediaTitle}>
                        {RMTitle}
                    </div>
                    <p className='regular-p'>{RMContent}</p>
                    {RMBullets.length > 0 && 
                    <ul className={`regular-p ${styles.richMediaBullets}`}>
                        {RMBullets.map((bullet,index) => <li key={index}>{bullet}</li>)}
                    </ul>
                    }
                </Col>
            </Row>
        </>
    )
}

export default RichMedia;