import Home from './pages/Home.js';
import './App.css';
import './Fonts.css';
import { Route, Routes } from 'react-router-dom';
import Nosotros from './pages/Nosotros.js';
import Layout from './pages/Layout.js';

function App() {
  return (
    <>
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='nosotros' element={<Nosotros />} />
      </Route>
    </Routes>
    </>
  );
}

export default App;
