import { Button, Carousel, Col, Container, Row, Image } from 'react-bootstrap';
import usePageSEO from '../Utils/usePageSEO';
import RichMedia from '../components/RichMedia';
import IconText from '../components/IconText';

import './Home.css';
import picMission from '../Assets/hand-holding-eco-friendly-green-living-symbol 2.jpg';
import { homeContentES, homeContentEN } from '../Assets/home-content';
import logoRecuperapp from '../Assets/logo-recuperapp.png';
import bgServicios from '../Assets/bg-servicios.svg';
import sustenlogicIso from '../Assets/sustenlogic-iso.svg';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import { useOutletContext } from 'react-router-dom';


function Home() {
    
    const { lang } = useOutletContext();
    let homeContent = lang === 'es' ? homeContentES : homeContentEN
    
    usePageSEO(homeContent.homeSEO);

    return(
        <>
        <Container fluid className='home-hero'>
            <Row>
                <Col className='d-flex flex-column align-items-start align-items-lg-center'>
                    <h1 className='home-title text-white text-start text-lg-center'>
                        {homeContent.heroTitulo}
                        </h1>
                    <p className='regular-p text-white text-start text-lg-center home-subtitle'>
                        {homeContent.heroP1}
                    </p>
                    <Button className='btn-cta' href='#contacto'>
                        {homeContent.heroCta}
                    </Button>
                    <div className='our-mission'>
                        <Row>
                            <Col xs={12} lg={{span: 6, order: 'last'}}>
                            <img src={picMission} alt={homeContent.misionPicAlt} className='pic-mission img-fluid'/>
                            </Col>
                            <Col xs={12} lg={{span: 6, order: 'first'}} className='pe-lg-5'>
                            <h3 className='our-mission-title text-white mt-lg-5'>
                                {homeContent.misionTitulo}
                            </h3>
                            <p className='regular-p text-white mt-lg-3'>
                                {homeContent.misionP1}
                            </p>
                            </Col>
                        </Row>
                    </div>
                </Col>    
            </Row>
        </Container>
        <Container className='home-elegir'>
            <Row>
                <Col>
                    <h3 className='text-main-black text-center mb-5'>
                        {homeContent.elegirTitulo}
                    </h3>
                </Col>
            </Row>
            <Row className='justify-content-around d-none d-md-flex'>
                <Col xs={12} md={5}>
                    <IconText Icon={homeContent.iconTextElegir1.Icon} Title={homeContent.iconTextElegir1.Title} Content={homeContent.iconTextElegir1.Content} />
                    <IconText Icon={homeContent.iconTextElegir2.Icon} Title={homeContent.iconTextElegir2.Title} Content={homeContent.iconTextElegir2.Content} />
                </Col>
                <Col xs={12} md={5}>
                    <IconText Icon={homeContent.iconTextElegir3.Icon} Title={homeContent.iconTextElegir3.Title} Content={homeContent.iconTextElegir3.Content} />
                    <IconText Icon={homeContent.iconTextElegir4.Icon} Title={homeContent.iconTextElegir4.Title} Content={homeContent.iconTextElegir4.Content} />
                </Col>
            </Row>
            <Row className='d-lg-none'>
                <Col>
                    <Carousel controls={true} indicators={true} className='mb-5' variant='dark'>
                        <Carousel.Item>
                            <IconText Icon={homeContent.iconTextElegir1.Icon} Title={homeContent.iconTextElegir1.Title} Content={homeContent.iconTextElegir1.Content} />
                            <IconText Icon={homeContent.iconTextElegir2.Icon} Title={homeContent.iconTextElegir2.Title} Content={homeContent.iconTextElegir2.Content} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <IconText Icon={homeContent.iconTextElegir3.Icon} Title={homeContent.iconTextElegir3.Title} Content={homeContent.iconTextElegir3.Content} />
                            <IconText Icon={homeContent.iconTextElegir4.Icon} Title={homeContent.iconTextElegir4.Title} Content={homeContent.iconTextElegir4.Content} />
                        </Carousel.Item>
                    </Carousel>
                </Col>
            </Row>
        </Container>
        <Container fluid className='bg-third-green-rounded home-soluciones'>
            <Row className='mb-5'>
                <Col>
                    <h3 className='text-white text-center'>
                        {homeContent.solucionesTitulo}
                    </h3>
                </Col>
            </Row>
            <Row className='gx-5 justify-content-around'>
                <Col lg={4}>
                    <IconText Icon={homeContent.iconTextSoluciones1.Icon} Title={homeContent.iconTextSoluciones1.Title} Content={homeContent.iconTextSoluciones1.Content} TextColor='text-white' ContentCentered={true}  />
                </Col>
                <Col lg={4}>
                    <IconText Icon={homeContent.iconTextSoluciones2.Icon} Title={homeContent.iconTextSoluciones2.Title} Content={homeContent.iconTextSoluciones2.Content} TextColor='text-white' ContentCentered={true}  />
                </Col>
                <Col lg={4}>
                    <IconText Icon={homeContent.iconTextSoluciones3.Icon} Title={homeContent.iconTextSoluciones3.Title} Content={homeContent.iconTextSoluciones3.Content} TextColor='text-white' ContentCentered={true}  />
                </Col>
            </Row>
        </Container>
        <Container className='home-proyectos' id='recuperapp'>
            <Row className='justify-content-center'>
                <Col md={8} className='d-flex flex-column align-items-center'>
                    <h3 className='text-center mb-5'>{homeContent.proyectosTitulo}</h3>
                    <img src={logoRecuperapp} alt={homeContent.proyectosLogoRecuperappAlt} className='logo-recuperapp mb-5'/>
                    <p className='regular-p text-center mb-5'>
                        {homeContent.proyectosRecuperappP1}
                    </p>
                    <Button className='btn-cta' href='#contacto'>
                        {homeContent.proyectosCta}
                    </Button>
                </Col>
            </Row>
        </Container>
        <Container fluid className='home-servicios'>
            <Row>
                <Col>
                    <img src={bgServicios} alt='' className='img-fluid w-100' />
                </Col>
            </Row>
            <Row className='home-servicios-container justify-content-center'>
                <Col className='px-0 d-flex flex-column align-items-center' md={12}>
                    <h3 className='text-center mb-5'>
                        {homeContent.serviciosTitulo}
                    </h3>
                    <RichMedia 
                        RMPic={homeContent.richMediaCto.RMPic} 
                        RMPicPosition={homeContent.richMediaCto.RMPicPosition}
                        RMTitle={homeContent.richMediaCto.RMTitle}
                        RMContent={homeContent.richMediaCto.RMContent}
                        RMBullets={homeContent.richMediaCto.RMBullets}
                    />
                    <RichMedia 
                        RMPic={homeContent.richMediaDigProd.RMPic} 
                        RMPicPosition={homeContent.richMediaDigProd.RMPicPosition}
                        RMTitle={homeContent.richMediaDigProd.RMTitle}
                        RMContent={homeContent.richMediaDigProd.RMContent}
                        RMBullets={homeContent.richMediaDigProd.RMBullets}
                    />
                    <RichMedia 
                        RMPic={homeContent.richMediaCmo.RMPic} 
                        RMPicPosition={homeContent.richMediaCmo.RMPicPosition}
                        RMTitle={homeContent.richMediaCmo.RMTitle}
                        RMContent={homeContent.richMediaCmo.RMContent}
                        RMBullets={homeContent.richMediaCmo.RMBullets}
                    />
                    <Button className='btn-cta' href='#contacto'>
                        {homeContent.serviciosCta}
                    </Button>
                </Col>
            </Row>
        </Container>
        <Container fluid className='bg-third-green-rounded home-contacto' id='contacto'>
            <Row className='mx-0'>
                <Col className='d-flex flex-column align-items-center px-4'>
                    <div className='home-compromiso'>
                        <Image src={sustenlogicIso} className='mb-4' />
                        <h3 className='text-white mb-3'>
                            {homeContent.contactoTitulo}
                        </h3>
                        <p className='regular-p text-white mb-0'>
                            {homeContent.contactoP1}
                        </p>
                    </div>
                </Col>
            </Row>
            <Row className='justify-content-center mx-0'>
                <Col md={8} lg={5}>
                    <ContactForm lang={lang} />
                </Col>
            </Row>
        </Container>
        <Footer lang={lang} />
        </>
    )
}

export default Home;