import { Container, Row, Col, Stack } from "react-bootstrap";
import './Footer.css';
import sustenlogicLogo from '../Assets/sustenlogic-logo-footer.svg';
import iconMail from '../Assets/mail.svg';
import iconLinkedin from '../Assets/lk.svg';
import iconInstagram from '../Assets/in.svg';

function Footer({lang}) {
    const year = new Date();
    return (
        <>
        <Container className='footer-section'>
            <Row>
                <Col>
                    <Stack direction="horizontal" gap={3}>
                        <div>
                            <img src={sustenlogicLogo} alt='' />
                        </div>
                        <div className="ms-auto">
                            <a href="mailto:contacto@sustenlogic.com">
                                <img src={iconMail} alt="" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/sustenlogic">
                                <img src={iconLinkedin} alt="" />
                            </a>
                        </div>
                        <div>
                            <a href="https://www.instagram.com/sustenlogic">
                                <img src={iconInstagram} alt="" />
                            </a>
                        </div>
                        
                    </Stack>
                </Col>
            </Row>
        </Container>
        <Container fluid className="legal-section">
            <Row className="mx-0">
                <Col>
                    <div className="legal-text">
                    &copy; {year.getFullYear()} Copyright <span className="fw-bold">Sustenlogic Tecnología SAS</span>. {lang === 'es' ? 'Todos los derechos reservados' : 'All rights reserved'}
                    </div>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default Footer;