import iconCheck from '../Assets/icon-check.png';

export const nosotrosContentES = {
    heroTitle : 'Escuchamos a las personas, entendemos sus problemas, creamos soluciones',
    heroSubTitle : 'Somos una compañía generadora de soluciones tecnológicas y servicios de vanguardia. Trabajamos en soluciones que acompañan el día a día de las personas, mejorando su vida de manera responsable.',
    heroCta : 'CONTÁCTANOS',
    p1: 'Con foco en la <span class="fw-bold">responsabilidad social empresarial y ambiental</span>, es que vamos en busca de la certificación de empresa B, siguiendo estándares de gestión y transparencia que caracterizan a Sustenlogic Tecnología.',
    p2 : 'Desde nuestros comienzos, supimos cual era nuestro foco, vivimos lo que decimos, ese fue el motor para comenzar a trabajar en múltiples soluciones que permiten utilizar la tecnología en pos de las personas, mejorar su vida y optimizar recursos.',
    visionTitle : 'Lo que nos impulsa y nuestra visión',
    misionBox: {
        title : 'Misión',
        content : `Construir soluciones que se encuentren al alcance de todas las personas, usando la tecnología al servicio de la sociedad y <span class="fw-bold">sin comprometer los recursos de las generaciones futuras</span>.`
    },
    visionBox : {
        title : 'Visión',
        content : 'Ser una organización atractiva para quienes quieran <span class="fw-bold">transformar la sociedad a través de la tecnología</span>, siendo referentes debido al impacto de nuestras acciones.'
    },
    valoresTitle : 'Nuestros valores', 
    valores1 : {
        Icon: iconCheck,
        Title : 'Transparencia',
        Content : 'Diciendo la verdad, siendo personas honestas, auténticas y confiables.'
    },
    valores2 : {
        Icon: iconCheck,
        Title : 'Coherencia',
        Content : 'Sin contradecirnos entre cómo actuamos y lo que decimos.'
    },
    valores3 : {
        Icon: iconCheck,
        Title : 'Calidad',
        Content : 'Desde el inicio y en cada servicio que entregamos.'
    },
    footerTitle : {
        p1 : 'Nuestras acciones de hoy, son las consecuencias del mañana',
        p2 : 'Bienvenidos a Sustenlogic Tecnología'
    },
    nosotrosSEO : {
        title : 'Sustenlogic | Innovación Tecnológica para la Sostenibilidad Empresarial',
        description : 'Tecnología innovadora para empresas que buscan sostenibilidad y eficiencia. En Sustenlogic, implementamos soluciones que impulsan el crecimiento responsable y optimizan los recursos.',
        keywords : ['sostenibilidad', 'transformación', 'negocio', 'soluciones sostenibles'],
        ogTitle : 'Sustenlogic | Innovación Tecnológica para la Sostenibilidad Empresarial',
        ogDescription : 'Tecnología innovadora para empresas que buscan sostenibilidad y eficiencia. En Sustenlogic, implementamos soluciones que impulsan el crecimiento responsable y optimizan los recursos.',
        ogImage : 'https://i.imgur.com/7s8PtFA.jpeg',
        ogUrl : process.env.PUBLIC_URL,
        ogType : 'website'
    }
}

export const nosotrosContentEN = {
    heroTitle : 'We listen to people, understand their problems, create solutions',
    heroSubTitle : 'We are a company generating cutting-edge technological solutions and services. We work on solutions that accompany people’s daily lives, improving their lives responsibly.',
    heroCta : 'CONTACT US',
    p1: 'With a focus on <span class="fw-bold">corporate social and environmental responsibility</span>, we pursue B Corporation certification, adhering to management and transparency standards that characterize Sustenlogic Technology.',
    p2 : 'Since our beginnings, we have known our focus; we live what we say. This was the driving force to start working on multiple solutions that enable technology to serve people, improve their lives, and optimize resources.',
    visionTitle : 'What drives us and our vision',
    misionBox: {
        title : 'Mission',
        content : `To build solutions accessible to everyone, using technology to serve society and <span class="fw-bold">without compromising the resources of future generations</span>.`
    },
    visionBox : {
        title : 'Vision',
        content : 'To be an attractive organization for those who wish to <span class="fw-bold">transform society through technology</span>, being a reference due to the impact of our actions.'
    },
    valoresTitle : 'Our values', 
    valores1 : {
        Icon: iconCheck,
        Title : 'Transparency',
        Content : 'Telling the truth, being honest, authentic, and trustworthy.'
    },
    valores2 : {
        Icon: iconCheck,
        Title : 'Consistency',
        Content : 'Not contradicting ourselves between how we act and what we say.'
    },
    valores3 : {
        Icon: iconCheck,
        Title : 'Quality',
        Content : 'From the beginning and in every service we deliver.'
    },
    footerTitle : {
        p1 : 'Our actions today are the consequences of tomorrow',
        p2 : 'Welcome to Sustenlogic Technology'
    },
    nosotrosSEO : {
        title : 'Sustenlogic | Technological Innovation for Business Sustainability',
        description : 'Innovative technology for companies seeking sustainability and efficiency. At Sustenlogic, we implement solutions that drive responsible growth and optimize resources.',
        keywords : ['sustainability', 'transformation', 'business', 'sustainable solutions'],
        ogTitle : 'Sustenlogic | Technological Innovation for Business Sustainability',
        ogDescription : 'Innovative technology for companies seeking sustainability and efficiency. At Sustenlogic, we implement solutions that drive responsible growth and optimize resources.',
        ogImage : 'https://i.imgur.com/7s8PtFA.jpeg',
        ogUrl : process.env.PUBLIC_URL,
        ogType : 'website'
    }
}
