import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Menu from "../components/Menu";
import { useState } from "react";

function Layout() {
    const [lang, setLang] = useState('es');

    return (
        <>
            <Container fluid>
                <Row>
                    <Col className="px-0">
                        <Menu setLang={setLang} lang={lang} />
                        <Outlet context={{lang}} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Layout;