import { Formik } from "formik";
import * as yup from 'yup';
import { Form, Row, Col, Button } from "react-bootstrap";
import styles from './ContactForm.module.css';
import { formContentES, formContentEN } from './ContacFormContent';
import axios from "axios";
import ReCaptcha from "react-google-recaptcha";
import { useState } from "react";

function ContactForm({lang}){
    const {
        formTitle, 
        formDesc, 
        formNameLabel, 
        formNameInput,
        formNameValidationError, 
        formEmailLabel, 
        formEmailInput, 
        formEmailValidationError,
        formMsgLabel, 
        formMsgInput, 
        formBtn,
        formBtnSubmitting,
        formValidationOk,
        formValidationError,
        formEmailValidationOk,
        formNameValidationOk,
        formValidationRecaptchaError
    } = lang === 'es' ? formContentES : formContentEN;

    const initialValues = {
        contactName: '',
        contactEmail: '',
        message: ''
    }

    const formSchema = yup.object().shape({
        contactName: yup.string().required(formNameValidationError),
        contactEmail: yup.string().email(formEmailValidationError).required(formEmailValidationError)
    });

    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    }

    
    const handleSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {
        if (!recaptchaToken) {
            setStatus({ error: formValidationRecaptchaError });
            return;
        }

        try {
            setSubmitting(true);
            const postUrl = 'https://site.sustenlogic.com/landing-email-sender';
            
            const response = await axios.post(postUrl, {...values, recaptchaToken});
            setStatus({ success: response.data.message });
            resetForm();
            
        } catch (error) {
            setStatus({ error: formValidationError })
            console.error('Error form:', error)
        } finally {
            setSubmitting(false);
            setRecaptchaToken(null);
        }
    };

    return (
        <>
            <h3 className='text-white text-center mb-4'>
                        {formTitle}
                    </h3>
                    <p className='regular-p text-white text-center'>
                        {formDesc}
                    </p>
                    <Formik
                        validationSchema={formSchema}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                    >
                        {({ isSubmitting, status, values, handleChange, touched, errors, handleSubmit }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Row className='justify-content-center'>
                                    <Form.Group  controlId='validationFormikFullname'>
                                        <Form.Label className={`${styles.labelForm} text-white`}>{formNameLabel}</Form.Label>
                                        <Form.Control 
                                            type='text'
                                            name='contactName'
                                            value={values.contactName}
                                            onChange={handleChange}
                                            placeholder={formNameInput}
                                            isValid={touched.contactName && !errors.contactName}
                                            isInvalid={errors.contactName}
                                            className={styles.customFormControl}
                                        />
                                        <Form.Control.Feedback>
                                            {formNameValidationOk}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>
                                            {formNameValidationError}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group  controlId='validationFormikEmail'>
                                        <Form.Label className={`${styles.labelForm} text-white mt-4`}>{formEmailLabel}</Form.Label>
                                        <Form.Control 
                                            type='email'
                                            name='contactEmail'
                                            value={values.contactEmail}
                                            onChange={handleChange}
                                            placeholder={formEmailInput}
                                            isValid={touched.contactEmail && !errors.contactEmail}
                                            isInvalid={errors.contactEmail}
                                            className={styles.customFormControl}
                                        />
                                        <Form.Control.Feedback>
                                            {formEmailValidationOk}
                                        </Form.Control.Feedback>
                                        <Form.Control.Feedback type='invalid'>
                                            {formEmailValidationError}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group  controlId='validationFormikMsg'>
                                        <Form.Label className={`${styles.labelForm} text-white mt-4`}>{formMsgLabel}</Form.Label>
                                        <Form.Control 
                                            as="textarea"
                                            rows={3}
                                            name='message'
                                            value={values.message}
                                            onChange={handleChange}
                                            placeholder={formMsgInput}
                                            className={styles.customFormControl}
                                        />
                                        <Form.Control.Feedback>
                                            {formValidationOk}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className="justify-content-center">
                                    <Col md={4} className="pt-4 align-items-center d-flex flex-column">
                                    <ReCaptcha 
                                        sitekey="6LcJ4UUeAAAAAPN7Kfn9mR0eyA1VcKIO0kERDoWC"
                                        onChange={handleRecaptchaChange}
                                    />
                                    <Button className='btn-cta d-block mt-4 mx-auto mb-0' type='submit'>
                                        {isSubmitting ?  formBtnSubmitting : formBtn}
                                    </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {status?.error && (
                                            <div className="alert alert-danger text-center mt-3">
                                                {status.error}
                                            </div>
                                        )}
                                        {status?.success && (
                                            <div className="alert alert-success text-center mt-3">
                                                {status.success}
                                            </div>
                                        )}
                                    </Col>
                                </Row>

                            </Form>
                        )}
                    </Formik>
        </>
    )
}

export default ContactForm;