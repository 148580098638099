export const formContentES = {
    formTitle : 'Contáctanos para una cotización',
    formDesc : 'Regístrate ahora y descubre cómo nuestras soluciones pueden impulsar tu negocio. Completa el formulario y nos pondremos en contacto contigo.',
    formNameLabel : 'Nombre completo*:',
    formNameInput : 'Escribe aquí tu nombre completo',
    formNameValidationOk: 'El formato de nombre es correcto.',
    formNameValidationError : 'Por favor, ingresa tu nombre completo.',
    formEmailLabel : 'Correo electrónico*:',
    formEmailInput : 'Escribe aquí tu correo electrónico',
    formEmailValidationOk : 'El formato de correo electrónico es correcto',
    formEmailValidationError : 'Por favor, ingresa tu correo electrónico.',
    formMsgLabel : 'Mensaje:',
    formMsgInput : 'Escribe aquí tu mensaje',
    formBtn : 'ENVIAR',
    formBtnSubmitting: 'ENVIANDO...',
    formValidationOk : 'El mensaje fue enviado correctamente!',
    formValidationError: 'No se pudo enviar el mensaje. Intente nuevamente mas tarde.',
    formValidationRecaptchaError: 'Por favor, verifica que no eres un robot!'
}

export const formContentEN = {
    formTitle : 'Contact us for a quote',
    formDesc : 'Sign up now and discover how our solutions can boost your business. Fill out the form and we will get in touch with you.',
    formNameLabel : 'Full name*:',
    formNameInput : 'Enter your full name here',
    formNameValidationOk: 'Name format is correct.',
    formNameValidationError : 'Please enter your full name.',
    formEmailLabel : 'Email address*:',
    formEmailInput : 'Enter your email address here',
    formEmailValidationOk : 'Email address format is correct.',
    formEmailValidationError : 'Please enter your email address.',
    formMsgLabel : 'Message:',
    formMsgInput : 'Enter your message here',
    formBtn : 'SEND',
    formBtnSubmitting: 'SENDING...',
    formValidationOk : 'Message sent successfully!',
    formValidationError: 'Error sending message. Please, try again later.',
    formValidationRecaptchaError: 'Please, verify you are not a robot!'
}
